import React, { useState, useEffect } from 'react';
import { getInductionQuestions, submitForm } from "../services/ApiService";
import Constants from "../Constants";
import Header from '../components/Header';

const TakeTest = ({ formData, submissionId, setCurrentStep, setSubmissionResult }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  useEffect(() => {
    getInductionQuestions(window.location.hash.substring(1)).then(res => setQuestions(res));
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      validateQuestion(questions[currentQuestionIndex]);
    }
  }, [questions, currentQuestionIndex]);

  const validateQuestion = (question) => {
    let isValid = false;
    if (question.controlType === Constants.ControlTypes.CONTROL_CHOICE) {
      isValid = question.selectedOption !== undefined;
    } else if (question.controlType === Constants.ControlTypes.CONTROL_CHOICE_MULTI) {
      isValid = question.selectedOptions && question.selectedOptions.length > 0;
    } else {
      isValid = question.answer !== undefined && question.answer.trim() !== '';
    }
    setIsNextButtonDisabled(!isValid);
  };

  const handleChange = (e, questionIndex, controlType, nextQuestionId) => {
    let newQuestions = [...questions];
    let question = newQuestions[questionIndex];

    if (controlType === Constants.ControlTypes.CONTROL_CHOICE) {
      question.selectedOption = e.target.value;
    } else if (controlType === Constants.ControlTypes.CONTROL_CHOICE_MULTI) {
      const selectedOptions = Array.from(
        document.querySelectorAll(`input[name='${question.questionID}_repeatmulti']:checked`)
      ).map(input => input.value);
      question.selectedOptions = selectedOptions;
    } else {
      question.answer = e.target.value;
    }

    setQuestions(newQuestions);

    validateQuestion(question);

    if (nextQuestionId > 0) {
      const nextQuestionIndex = questions.findIndex(q => q.questionID === nextQuestionId);
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const handleNextClick = () => {
    const currentQuestion = questions[currentQuestionIndex];

    if (currentQuestionIndex === questions.length - 1 || currentQuestion.showQuestionID === -1) {
      processSubmission();
    } else {
      const nextQuestionIndex = questions.findIndex(q => q.questionID === currentQuestion.showQuestionID);
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const processSubmission = async () => {
    const answers = questions.map((question, index) => {
      let answer = {
        qnumber: index + 1,
        qid: question.questionID,
        qtype: question.controlType,
        qchoicevalue: question.selectedOption || '',
        qmultivalue: (question.selectedOptions || []).join('|'),
        qtextvalue: question.answer || '',
      };
      return answer;
    });

    var data = { submissionGuid: submissionId, answers: answers };

    submitForm(data).then(res => {
      setSubmissionResult(res);

      if (res.result !== 1) {
        alert("Your submission has failed. Please check the data and try again.");
      } else {
        if (formData.inductionIncludeQuestionnaire) {
          setCurrentStep(Constants.Steps.STEP_QUESTIONAIRE);
        } else if (formData.inductionIncludeStatement) {
          setCurrentStep(Constants.Steps.STEP_SIGNATURE);
        } else {
          setCurrentStep(Constants.Steps.STEP_COMPLETION);
        }
      }
    });
  };

  return (
    <div>
      <div className="pf-content">
        {!questions.length && <>Loading...</>}
        {questions.length > 0 && questions.map((item, index) => (
          <div
            key={item.questionID}
            className="question"
            style={{ display: index <= currentQuestionIndex ? 'block' : 'none' }}
          >
            <div className="q-top" data-qid={item.questionID}>
              <div className="q-number">{index + 1}</div>
              <div className="q-question" dangerouslySetInnerHTML={{ __html: item.question }}></div>
            </div>
            <div id="q-answer">
              {item.controlType === Constants.ControlTypes.CONTROL_CHOICE && (
                item.options.map((option, i) => (
                  <div key={i} className="radio">
                    <label htmlFor={`${item.questionID}_repeat_${i}`}>
                      <input
                        className="repeat-option"
                        id={`${item.questionID}_repeat_${i}`}
                        name={`${item.questionID}_repeat`}
                        value={option.value}
                        type="radio"
                        onChange={(e) => handleChange(e, index, Constants.ControlTypes.CONTROL_CHOICE, option.showQuestionID)}
                      />
                      {option.text}
                    </label>
                  </div>
                ))
              )}
              {item.controlType === Constants.ControlTypes.CONTROL_CHOICE_MULTI && (
                item.options.map((option, i) => (
                  <div key={i} className="checkbox">
                    <input
                      className="repeat-multi-option"
                      id={`${item.questionID}_repeatmulti_${i}`}
                      name={`${item.questionID}_repeatmulti`}
                      value={option.value}
                      type="checkbox"
                      onChange={(e) => handleChange(e, index, Constants.ControlTypes.CONTROL_CHOICE_MULTI, option.showQuestionID)}
                    />
                    <label htmlFor={`${item.questionID}_repeatmulti_${i}`}>{option.text}</label>
                  </div>
                ))
              )}
              {item.controlType >= Constants.ControlTypes.CONTROL_TEXT && (
                <input
                  type="text"
                  name={`${item.questionID}_freetext`}
                  id={`${item.questionID}_freetext`}
                  className="form-control text"
                  onChange={(e) => handleChange(e, index, item.controlType, item.showQuestionID)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {questions.length > 0 &&
        <div className="pf-footer">
          <button
            className={`btn btn-secondary btn-next btn-lg ${isNextButtonDisabled ? 'disabled' : ''}`}
            onClick={handleNextClick}
            disabled={isNextButtonDisabled}
          >
            Next
          </button>
        </div>}
    </div>
  );
};

export default TakeTest;
