import React, { useState, useEffect } from 'react';
import { getQuestionaireQuestions, submitQuestionnaireForm } from "../services/ApiService";
import Constants from "../Constants";

const InductionQuestionnaire = ({ formData, submissionId, setCurrentStep }) => {
  const [questions, setQuestions] = useState(formData.questions);
  const [isValid, setIsValid] = useState(false);

  
  useEffect(() => {    
    getQuestionaireQuestions(window.location.hash.substring(1)).then(res => setQuestions(res))
  }, []);

  useEffect(() => {    
    if(questions) {
        validatePage();
    }
  }, [questions]);

  const handleOptionChange = (qid, optionIndex) => {
    setQuestions(questions.map(q => {
      if (q.questionID === qid) {
        return {
          ...q,
          selectedOption: optionIndex,
          additionalInfo: optionIndex !== -1 && q.options[optionIndex].needsInfo ? q.additionalInfo : ''
        };
      }
      return q;
    }));
  };

  const handleAdditionalInfoChange = (qid, value) => {
    setQuestions(questions.map(q => {
      if (q.questionID === qid) {
        return { ...q, additionalInfo: value };
      }
      return q;
    }));
  };

  const validatePage = () => {
    let valid = true;
    questions.forEach(q => {
      if (q.selectedOption === undefined || (q.options[q.selectedOption]?.needsInfo && !q.additionalInfo?.trim())) {
        valid = false;
      }
    });
    setIsValid(valid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) return;

    const results = questions.map((q, index) => ({
      qnumber: index + 1,
      qid: q.questionID,
      qchoicevalue: q.options[q.selectedOption]?.value,
      qtextvalue: q.additionalInfo,
      qquestiontext: q.questionText,
      qchoicetext: q.options[q.selectedOption]?.text,
    }));


    var data = { submissionGuid: submissionId, answers: results };

    submitQuestionnaireForm(data).then(res => {
        if(res.result != 1) {
         alert("Your submission has failed. Please check the data and try again.");
        } else {
            if(formData.inductionIncludeStatement) {
                setCurrentStep(Constants.Steps.STEP_SIGNATURE);
            } else {
                setCurrentStep(Constants.Steps.STEP_COMPLETION);
            }
        }
    });
  };

  return (
    <div>
      <div className="pf-content">
        <div className="pf-intro-content">
          <h3>{formData.inductionQuestionnaireHeading}</h3>
          <p dangerouslySetInnerHTML={{__html: formData.inductionQuestionnaireIntroduction}}></p>
        </div>
        {!questions && <>Loading...</>}
        {questions && questions.map((q, qIndex) => (
          <div className="question" key={q.questionID} data-qid={q.questionID} data-qorder={qIndex + 1}>
            <div className="q-top" data-qid={q.questionID}>
              <div className="q-number">{qIndex + 1}</div>
              <div className="q-question" dangerouslySetInnerHTML={{__html: q.questionText}}></div>
            </div>

            <div id="q-answer">
              {q.options.map((option, oIndex) => (
                <div className="radio" key={oIndex}>
                  <label htmlFor={`${q.questionID}_repeat_${oIndex}`}>
                    <input
                      className="repeat-option"
                      data-arai={option.needsInfo.toString()}
                      id={`${q.questionID}_repeat_${oIndex}`}
                      name={`${q.questionID}_repeat`}
                      value={option.value}
                      type="radio"
                      checked={q.selectedOption === oIndex}
                      onChange={() => handleOptionChange(q.questionID, oIndex)}
                    />
                    {option.text}
                  </label>
                </div>
              ))}

              {q.options[q.selectedOption]?.needsInfo && (
                <div className="additional-info">
                  <label htmlFor={`${q.questionID}_additionalinfo`}>Please provide more information and any relevant details</label>
                  <textarea
                    name={`${q.questionID}_additionalinfo`}
                    id={`${q.questionID}_additionalinfo`}
                    className="form-control text additional-text"
                    value={q.additionalInfo || ''}
                    onChange={(e) => handleAdditionalInfoChange(q.questionID, e.target.value)}
                  ></textarea>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="pf-footer">
        <button
          className={`btn btn-secondary btn-lg btn-next ${isValid ? '' : 'disabled'}`}
          onClick={handleSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default InductionQuestionnaire;