
const Header = ({inductionLogo, inductionTitle}) => {

    return (
    <>
      <div className="pf-header">
        <div className="row">
            <div className="col-sm-3">
                <div className="responsive-square logo">
                    <div className="inr">
                        <div className="helper"></div>
                        {inductionLogo && <img src={inductionLogo?.replace("~", "https://www.proman-uk.com")} alt="Logo" />}
                        </div>
                    </div>
                </div>
                <div className="col-sm-9">
                    <h1>{inductionTitle}</h1>
                </div>
            </div>
        </div>
        <div className="shape-widget section-blue shape-bottom">
            <img width="1920" src="https://www.proman-uk.com/img/wave-section-blue.png" alt="Shape Background" />
        </div>
    </>
    )

}

export default Header;