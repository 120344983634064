import React, { useState, useEffect } from 'react';
import Constants from "../Constants";

const videoProps = {
  playerId: 'A4CJVdZu',
  wrapper: {
    position: 'relative',
    overflow: 'hidden'
  },
  iframe: {
    width: '100%',
    height: '100%',
    frameBorder: '0',
    scrolling: 'auto',
    style: {
      position: 'absolute',
    },
    allowFullScreen: true,
  },
};

const renderVideo = (id) => {

  return (<div
  style={{
    paddingBottom: "56.25%",
    ...videoProps.wrapper,
  }}
  className='jwplayer'
>
  <iframe
    { ...videoProps.iframe }
    src={ `//content.jwplatform.com/players/${id}-${videoProps.playerId}.html` }
  />
</div>);


}
export const getMediaContent = (proFormsData) => {
 const {
   inductionVideo,
   inductionPowerPoint,
   inductionPDF,
   inductionIntroductionMediaType
 } = proFormsData;

   switch (inductionIntroductionMediaType) {
     case 1:
       return inductionVideo ? (
         <div className="pf-intro-video">
           {renderVideo(inductionVideo)}
         </div>
       ) : null;
     case 2:
       return (
         <div className="pf-intro-powepoint">
           <iframe
             src={`https://view.officeapps.live.com/op/embed.aspx?src=${inductionPowerPoint.replace(
               '~',
               'https://www.proman-uk.com'
             )}`}
             frameBorder="0"
             width="100%"
             height="395px"
           ></iframe>
         </div>
       );
     case 3:
       return (
         <div className="pf-intro-pdf">
           <iframe
             src={`https://docs.google.com/viewer?embedded=true&url=${inductionPDF.replace(
               '~',
               'https://www.proman-uk.com'
             )}`}
             width="100%"
             height="500px"
           >
             <p>
               Your browser doesn't support this file, please{' '}
               <a href={inductionPDF}>download it here</a>
             </p>
           </iframe>
         </div>
       );
     case 4:
       const video = inductionVideo ? (
         <div className="pf-intro-video">
           {renderVideo(inductionVideo)}
         </div>
       ) : null;
       const powerpoint = (
         <div className="pf-intro-powerpoint">
           <iframe
             src={`https://view.officeapps.live.com/op/embed.aspx?src=${inductionPowerPoint.replace(
               '~',
               'https://www.proman-uk.com'
             )}`}
             frameBorder="0"
             width="100%"
             height="395px"
           ></iframe>
         </div>
       );
       return (
         <>
           {video}
           {video && powerpoint && <hr />}
           {powerpoint}
         </>
       );
     default:
       return null;
   }
 };


export const Intro = ({ formData, setCurrentStep }) => {
  const [isVideoWatched, setIsVideoWatched] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsVideoWatched(e.target.checked);
  };

  const handleButtonClick = (e) => {
    if (!isVideoWatched) {
      alert("Please confirm you have watched the video");
      e.preventDefault();
    }
    
    setCurrentStep(Constants.Steps.STEP_TEST);
  };

  const {
    inductionIntroduction,
    inductionWatchedConfirmationText
  } = formData;


  return (
    <div>
      <div className="pf-content">
        <div className="pf-intro-content">
          {inductionIntroduction && (
            <div
              className="intro"
              dangerouslySetInnerHTML={{ __html: inductionIntroduction }}
            />
          )}
        </div>

        <div className="spacing-widget small"></div>

        {getMediaContent(formData)}

        <div className="spacing-widget small"></div>

        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              className="watched-video-checkbox"
              id="watchedVideo"
              checked={isVideoWatched}
              onChange={handleCheckboxChange}
            />{' '}
            <strong>{inductionWatchedConfirmationText || 'I have viewed the induction'}</strong>
          </label>
        </div>
      </div>

      <div className="pf-footer text-center">
        <button
          className={`btn btn-secondary btn-lg ${!isVideoWatched ? 'disabled' : ''}`}
          onClick={handleButtonClick}
          disabled={!isVideoWatched}
        >
          Start Induction
        </button>
      </div>
    </div>
  );
};
