import React, { useState } from 'react';
import { submitAccessRequest } from "../services/ApiService";
import Constants from "../Constants";
import Header from '../components/Header';


const InductionAccessRequest = ({ formData, setCurrentStep, setSubmissionId }) => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    dob: null,
    address: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    clearError(name);
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const clearError = (item) => {
    const errors = formErrors;
    delete errors[item];
    setFormErrors(errors);
  };

  const {
    inductionLogo,
    inductionTitle,
    inductionIncludeDOB,
    inductionIncludeAddress,
  } = formData;

  const validateForm = () => {
    const errors = {};
    if (!formState.name) errors.name = 'Please enter your name';
    if (!formState.email) {
      errors.email = 'Please enter your email address';
    } else if (!/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(formState.email)) {
      errors.email = 'Please enter a valid email address';
    }
    if (!formState.phone) {
      errors.phone = 'Please enter your mobile number';
    } else if (!/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/.test(formState.phone)) {
      errors.phone = 'Please enter a valid mobile number';
    }
    if (!formState.dob && inductionIncludeDOB) errors.dob = 'Please enter your date of birth';
    if (!formState.address && inductionIncludeAddress) errors.address = 'Please enter your address';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitted(true);
      // Handle form submission
      var submission = await submitAccessRequest(formState, formData.inductionGuid);
      if(submission && submission.status == "ok") {
        setSubmissionId(submission.submissionGuid);
        setCurrentStep(Constants.Steps.STEP_INTRO);
      }
    }
  };


  return (
    <div>
        <form onSubmit={handleSubmit} className="form">
        <div className="pf-content">
          <div className="form-group">
            <label htmlFor="name">What is your full name? (including surname)</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formState.name}
              onChange={handleInputChange}
              className="form-control"
            />
            {formErrors.name && <div className="error-label">{formErrors.name}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="email">What is your email address?</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              className="form-control"
            />
            {formErrors.email && <div className="error-label">{formErrors.email}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="phone">What is your mobile number?</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formState.phone}
              onChange={handleInputChange}
              className="form-control"
            />
            {formErrors.phone && <div className="error-label">{formErrors.phone}</div>}
          </div>
          {inductionIncludeDOB &&
          <div className="form-group">
            <label htmlFor="dob">What is your date of birth?</label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={formState.dob}
              onChange={handleInputChange}
              className="form-control"
            />
            {formErrors.dob && <div className="error-label">{formErrors.dob}</div>}
          </div>}

          {inductionIncludeAddress &&
          <div className="form-group">
            <label htmlFor="address">What is your address?</label>
            <textarea
              id="address"
              name="address"
              value={formState.address}
              onChange={handleInputChange}
              className="form-control"
            />
            {formErrors.address && <div className="error-label">{formErrors.address}</div>}
          </div>}
          </div>
            <div className="pf-footer text-center">
          <button type="submit" className="btn btn-secondary btn-lg">
            Next
          </button>
          </div>
        </form>
      </div>
  );
};

export default InductionAccessRequest;
