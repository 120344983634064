const baseApiUrl = "https://www.proman-uk.com/"; // "http://localhost:25291/"; 

async function getProFormData(id) {
    return fetch(baseApiUrl + 'api/protrain/getformdata?id=' + id).then(res => res.json());
}

async function getInductionQuestions(id) {
    return fetch(baseApiUrl + 'api/protrain/getinductionquestions?id=' + id).then(res => res.json());
}

async function getQuestionaireQuestions(id) {
    return fetch(baseApiUrl + 'api/protrain/getquestionairequestions?id=' + id).then(res => res.json());
}

async function getStatementQuestions(id) {
    return fetch(baseApiUrl + 'api/protrain/getstatementquestions?id=' + id).then(res => res.json());

}



async function submitAccessRequest(formData, induction) {
    
    const jsonHdr = new Headers();
    jsonHdr.append("Content-Type", "application/json");

    return fetch(baseApiUrl + 'api/protrain/submitaccessrequest', {
        method: "POST",
        body: JSON.stringify({
            ...formData,
            inductionGuid: induction
        }),
        headers: jsonHdr
      }).then(res => res.json());

}

async function submitForm(data) {
    
    const jsonHdr = new Headers();
    jsonHdr.append("Content-Type", "application/json");

    return fetch(baseApiUrl + 'api/protrain/submitform', {
        method: "POST",
        body: JSON.stringify(data),
        headers: jsonHdr
      }).then(res => res.json());

}
async function submitQuestionnaireForm(data) {
    
    const jsonHdr = new Headers();
    jsonHdr.append("Content-Type", "application/json");

    return fetch(baseApiUrl + 'api/protrain/submitquestionnaireform', {
        method: "POST",
        body: JSON.stringify(data),
        headers: jsonHdr
      }).then(res => res.json());

}
async function submitStatementForm(data) {
    
    const jsonHdr = new Headers();
    jsonHdr.append("Content-Type", "application/json");

    return fetch(baseApiUrl + 'api/protrain/submitstatementform', {
        method: "POST",
        body: JSON.stringify(data),
        headers: jsonHdr
      }).then(res => res.json());

}




export {
    getProFormData,
    getInductionQuestions,
    getQuestionaireQuestions,
    getStatementQuestions,
    submitAccessRequest,
    submitForm,
    submitQuestionnaireForm,
    submitStatementForm
}