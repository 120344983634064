import React, { useState, useEffect, useRef } from 'react';
import Constants from '../Constants';
import { getStatementQuestions, submitStatementForm } from "../services/ApiService";
import SignatureCanvas from 'react-signature-canvas';

const InductionSignature = ({ formData, submissionId, setCurrentStep }) => {
  const [statements, setStatements] = useState([]);
  const [isSignatureModified, setIsSignatureModified] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [signature, setSignature] = useState('');
  const sigCanvas = useRef();

  useEffect(() => {    
    getStatementQuestions(window.location.hash.substring(1)).then(res => setStatements(res))
  }, []);

  useEffect(() => {
    validatePage();
  }, [statements, isSignatureModified]);

  const handleOptionChange = (statementId, value) => {
    setStatements(statements.map(statement => 
      statement.statementID === statementId 
        ? { ...statement, selectedOption: value } 
        : statement
    ));
  };

  const validatePage = () => {
    const allStatementsFilled = statements.every(statement => statement.selectedOption !== undefined);
    setIsValid(allStatementsFilled && isSignatureModified);
  };

  const handleSignatureChange = () => {
    var b64 = sigCanvas.current.toDataURL("image/png");
    //b64 = b64.substring(22);
    setSignature(b64);
    setIsSignatureModified(true);
  };

  const clearSignature =() => {
    sigCanvas.current.clear();
    setIsSignatureModified(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) return;

    const results = statements.map((statement, index) => ({
      qnumber: index + 1,
      qid: statement.statementID,
      qchoicevalue: statement.selectedOption,
      qquestiontext: statement.statementText,
      qchoicetext: statement.selectedOption === 1 ? "I accept" : "I do not accept",
    }));

    const data = {
      submissionGuid: submissionId,
      answers: results,
      base64Signature: signature
    };

    
    submitStatementForm(data).then(res => {
        if(res.result != 1) {
            alert("Your submission has failed. Please check the data and try again.");
        } else {
            setCurrentStep(Constants.Steps.STEP_COMPLETION);
        }
    });
  };

  return (
    <div>

      <div className="pf-content">
        <div className="pf-intro-content">
          <h2>{formData.inductionStatementHeading}</h2>
          <p dangerouslySetInnerHTML={{__html: formData.inductionStatementIntroduction}}></p>
        </div>

        {statements.map((statement, index) => (
          <div className="question statement" key={statement.statementID} data-qid={statement.statementID} data-qorder={index + 1}>
            <div className="q-top" data-qid={statement.statementID}>
              <div className="q-number">{index + 1}</div>
              <div className="q-question" dangerouslySetInnerHTML={{__html: statement.statementText}}></div>
            </div>
            <div id="q-answer">
              <div className="radio">
                <label htmlFor={`${statement.statementID}_accept`}>
                  <input
                    className="repeat-option"
                    id={`${statement.statementID}_accept`}
                    name={`${statement.statementID}_repeat`}
                    value="1"
                    type="radio"
                    checked={statement.selectedOption === 1}
                    onChange={() => handleOptionChange(statement.statementID, 1)}
                  />
                  I accept
                </label>
              </div>
              <div className="radio">
                <label htmlFor={`${statement.statementID}_decline`}>
                  <input
                    className="repeat-option"
                    id={`${statement.statementID}_decline`}
                    name={`${statement.statementID}_repeat`}
                    value="0"
                    type="radio"
                    checked={statement.selectedOption === 0}
                    onChange={() => handleOptionChange(statement.statementID, 0)}
                  />
                  I do not accept
                </label>
              </div>
            </div>
          </div>
        ))}

        <div className="question">
          <div className="q-top">
            <div className="q-number"></div>
            <div className="q-question">Please provide a signature below</div>
          </div>
          <div id="q-answer">
          <button
          className="btn btn-secondary btn-lg btn-next"
          onClick={clearSignature}
        >
          Clear
        </button>
            <br/><br />
            <SignatureCanvas id="signature" onEnd={handleSignatureChange} ref={sigCanvas} canvasProps={{ border: '1px #ccc solid', style: { width: "100%", minHeight: "100px"}}} backgroundColor="#eee" penColor="#000" />
            
          </div>
        </div>
      </div>

      <div className="pf-footer">
        <button
          className={`btn btn-secondary btn-lg btn-next ${isValid ? '' : 'disabled'}`}
          onClick={handleSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default InductionSignature;
