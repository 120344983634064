export default {
    Steps: {
        STEP_ACCESS_REQUEST: 0,
        STEP_INTRO: 1,
        STEP_TEST: 2,
        STEP_QUESTIONAIRE: 3,
        STEP_SIGNATURE: 4,
        STEP_COMPLETION: 5
    },
    ControlTypes: {
        CONTROL_CHOICE: 1,
        CONTROL_CHOICE_MULTI: 2,
        CONTROL_TEXT: 3,
        CONTROL_NAME: 4,
        CONTROL_EMAIL: 5,
        CONTROL_PHONE: 6
    }
}