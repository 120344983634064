import React, { useEffect } from 'react';

const Completion = ({ formData, submission }) => {

  const defaultSuccessHeading = "Great news, you have made it to the next stage, please complete your Proman Registration.";
  const defaultSuccessContent = "";
  const defaultFailureHeading = "Thanks!";
  const defaultFailureContent = "<p>Thanks, we will be in touch as soon as we are able to progress your application</p>";

  if (!formData || !submission) {
    return <></>;
  }


  const {
    inductionLogo: logoUrl = 'https://www.proman-uk.com/App_Themes/PR/img/logo.png',
    inductionTitle: formTitle = '',
    inductionSuccessHeading: successHeading = defaultSuccessHeading,
    inductionSuccessContent: successContent = defaultSuccessContent,
    inductionFailedHeading: failureHeading = defaultFailureHeading,
    inductionFailedContent: failureContent = defaultFailureContent,
    inductionSuccessButtonUrl: buttonUrl,
    inductionSuccessButtonText: buttonText = "Proceed to Registration"
  } = formData;

  const resultTitle = submission.submissionTestPassed ? successHeading : failureHeading;
  const resultText = submission.submissionTestPassed ? successContent : failureContent;
  const btnBVisible = submission.submissionTestPassed && buttonUrl;

  return (
    <div>
      <div className="pf-content">
        <h3>{resultTitle}</h3>
        <div dangerouslySetInnerHTML={{ __html: resultText }}></div>
        <div className="spacing-widget small"></div>
        {btnBVisible && (
          <div>
            <a href={buttonUrl} className="btn btn-secondary btn-lg" target="_blank">{buttonText}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Completion;