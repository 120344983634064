import { useEffect, useState } from "react";
import { getProFormData } from "./services/ApiService";
import Modal from 'react-modal';

import { Intro, getMediaContent } from "./sections/Intro";
import InductionAccessRequest from './sections/InductionAccessRequest';
import Completion from "./sections/Completion";
import TakeTest from "./sections/InductionTakeTest";
import './ProTrain.scss';
import Constants from "./Constants";
import Header from "./components/Header";
import InductionQuestionnaire from "./sections/InductionQuestionaire";
import InductionSignature from "./sections/InductionSignature";

// Bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function App() {
  
  const [proFormData, setProFormData] = useState();  
  const [currentStep, setCurrentStep] = useState(Constants.Steps.STEP_ACCESS_REQUEST);  
  const [submissionId, setSubmissionId] = useState();  
  const [submissionResult, setSubmissionResult] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if(window.location.hash != null && window.location.hash != "") {
      getProFormData(window.location.hash.substring(1)).then((res) => {
        setProFormData(res);
      });
    } else {
      window.location.href = "https://www.proman-uk.com";
    }
  }, []);

  if(!proFormData) {
    return (
      <div id="App">
        <div id="page-wrp" className="proform-master">        
          <div className="pf-wrp">          
            Loading...
          </div>
        </div>
      </div>);
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    overlay: {
      zIndex: '99999999'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      maxWidth: "100%"
    },
  };

  return (
    <div id="App">
      <div id="page-wrp" className="proform-master">        
        <div className="pf-wrp">          
          <Header inductionLogo={proFormData?.inductionLogo} inductionTitle={proFormData?.inductionTitle} />
          {currentStep == Constants.Steps.STEP_ACCESS_REQUEST ? <InductionAccessRequest formData={proFormData} setCurrentStep={setCurrentStep} setSubmissionId={setSubmissionId} /> : <></>}
          {currentStep == Constants.Steps.STEP_INTRO ? <Intro formData={proFormData} setCurrentStep={setCurrentStep} /> : <></>}
          {currentStep == Constants.Steps.STEP_TEST ? <TakeTest formData={proFormData} setCurrentStep={setCurrentStep} submissionId={submissionId} setSubmissionResult={setSubmissionResult} /> : <></>}
          {currentStep == Constants.Steps.STEP_QUESTIONAIRE ? <InductionQuestionnaire formData={proFormData} setCurrentStep={setCurrentStep} submissionId={submissionId} /> : <></>}
          {currentStep == Constants.Steps.STEP_SIGNATURE ? <InductionSignature formData={proFormData} setCurrentStep={setCurrentStep} submissionId={submissionId} /> : <></>}
          {currentStep == Constants.Steps.STEP_COMPLETION ? <Completion formData={proFormData} setCurrentStep={setCurrentStep} submissionId={submissionId} submission={submissionResult} /> : <></>}
          {currentStep == Constants.Steps.STEP_TEST ? <>          
            <span className="video-trigger" onClick={openModal}>
              View Information
            </span>
          </> : <></>}
        </div>      
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        <button onClick={closeModal} className="modal-close-btn">&times;</button>
        <div className="modal-content">
          {getMediaContent(proFormData)}
        </div>
      </Modal>
    </div>
  );
}

export default App;